/**
 * /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 *
 * @format
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

ion-item.no-inner-padding {
	--inner-padding-start: 0px;
	--inner-padding-right: 0px;
	--padding-start: 4px;
	--padding-end: 4px;
}

ion-avatar.alert-icon {
	background-color: var(--ion-color-danger);
	justify-content: center;
	align-items: center;
	display: flex;

	img {
		display: flex;
		width: 70%;
		height: 70%;
	}
}
ion-avatar.info-icon {
	background-color: var(--ion-color-info);
	justify-content: center;
	align-items: center;
	display: flex;

	img {
		display: flex;
		width: 70%;
		height: 70%;
	}
}

.card {
	--background: var(--ion-color-step-100);
	border-radius: 20px 4px 20px 20px;
	margin: 10px;
}

.card::after {
	inset: 0px;
	position: absolute;
	content: '';
	opacity: 1;
	transition: var(--transition);
	z-index: 999;
	top: 10px;
	left: calc(100% - 20px);
	border-radius: 90px;
	width: 10px;
	height: 10px;
	background: white;
}

.submit-button {
	text-align: center;
}
