.amsterdam-acid-blue .shaka-controls-button-panel .material-icons-round {
  color: #EEE;
}
.amsterdam-acid-blue .shaka-controls-button-panel button:hover.material-icons-round {
  color: #FFF !important;
}
.amsterdam-acid-blue input[type=range]::-webkit-slider-thumb {
  background: #FFF;
  cursor: pointer;
}
.amsterdam-acid-blue input[type=range]::-moz-range-thumb {
  background: #FFF;
  cursor: pointer;
}
.amsterdam-acid-blue input[type=range]::-ms-thumb {
  background: #FFF;
  cursor: pointer;
}
.amsterdam-acid-blue .shaka-play-button[icon=pause],
.amsterdam-acid-blue .shaka-play-button[icon=play] {
  background-color: #4285F4;
}
.amsterdam-acid-blue .shaka-overflow-menu,
.amsterdam-acid-blue .shaka-settings-menu {
  background: #4285F4;
}
.amsterdam-acid-blue .shaka-overflow-menu span + span {
  font-size: 80%;
  color: #FFF;
  padding-top: 2px;
}
.amsterdam-acid-blue .shaka-overflow-menu button:hover,
.amsterdam-acid-blue .shaka-settings-menu button:hover {
  background: #3275E4;
  cursor: pointer;
}
.amsterdam-acid-blue .shaka-overflow-menu button:hover label,
.amsterdam-acid-blue .shaka-settings-menu button:hover label {
  cursor: pointer;
}
.amsterdam-acid-blue .shaka-overflow-menu button:focus,
.amsterdam-acid-blue .shaka-settings-menu button:focus {
  background: #3275E4;
  border: none;
  outline: none;
}
.amsterdam-acid-blue .shaka-overflow-menu button,
.amsterdam-acid-blue .shaka-settings-menu button {
  color: #FFF;
}
.amsterdam-acid-blue .shaka-captions-off {
  color: #BFBFBF;
}
.amsterdam-acid-blue .shaka-controls-button-panel > button {
  margin: 0;
  padding: 0;
  background-color: #4285F4;
  width: 40px;
  height: 40px;
}
.amsterdam-acid-blue .shaka-controls-button-panel > button.shaka-current-time {
  position: relative;
  left: 10px;
  z-index: 1000;
  color: #FFF;
  background-color: transparent;
  height: auto !important;
  width: auto !important;
  font-size: 12px;
}
.amsterdam-acid-blue .shaka-bottom-controls {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 94%;
  padding-bottom: 0;
  margin-bottom: 3%;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 40px;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-seek-bar-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 130px 0 40px;
  height: 40px;
  border-radius: 0;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-mute-button {
  z-index: 1000;
  position: relative;
  width: 50px;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-seek-bar {
  cursor: pointer;
  height: 40px;
  opacity: 0;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-small-play-button {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-overflow-menu-button {
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container {
  z-index: 10;
  left: 0;
  opacity: 0;
  width: 0px;
  -webkit-transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
  transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
  position: relative;
  height: 40px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  will-change: width;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container:hover,
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container:focus {
  display: block;
  width: 50px;
  opacity: 1;
  padding: 0;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 2px;
  left: -6px;
  top: 50%;
  margin-top: -1px;
  background-color: rgba(255, 255, 255, 0.2);
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container .shaka-volume-bar {
  height: 40px;
  top: 0;
  border-radius: 0;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin-top: 0;
  border-radius: 0 !important;
  cursor: pointer;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type=range]:focus {
  outline: none;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #FFF;
  background: transparent;
  margin-top: 0;
  border-radius: 0 !important;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-controls-button-panel .shaka-volume-bar-container input[type=range]::-ms-track {
  -webkit-appearance: none;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #FFF;
  background: transparent;
  margin-top: 0;
  border-radius: 0 !important;
}
.amsterdam-acid-blue .shaka-bottom-controls .shaka-mute-button:hover + div {
  opacity: 1;
  width: 50px;
  padding: 0;
}

