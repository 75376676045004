/** @format */

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
@font-face {
	font-family: din;
	src: url(../assets/fonts/FontsFree-Net-DINPro-1.ttf) format('opentype');
}
@font-face {
	font-family: din-mediun;
	src: url(../assets/fonts/FontsFree-Net-D-DIN-PRO-500-Medium.ttf) format('opentype');
}
@font-face {
	font-family: din-bold;
	src: url(../assets/fonts/FontsFree-Net-D-DIN-PRO-700-Bold.ttf) format('opentype');
}

* {
	font-family: 'din';
}

.modal-fullscreen {
	.modal-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		--width: 100vw !important;
		--height: 100vh !important;
	}
}

:root {
	--ion-tab-bar-color-selected: #428cff;
	--ion-tab-bar-color: #9c9c9c;

	--priority-1: #00bbf0;
	--priority-3: #5edde4;
	--priority-5: #0be370;
	--priority-8: #ffeb3b;
	--priority-13: #ffad00;
	--priority-20: #e86c0c;
	--priority-40: #ff0101;
	--priority-100: #7600ff;

	--mac: #c1380a;
	--met: #5cff8a;
	--mea: #6875fd;
	--peo: #42b6ff;
	--env: #ff824f;
	--mat: #fbc974;

	--ion-color-primary: #e31114;
	--ion-color-primary-rgb: 255, 209, 0;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #e0b800;
	--ion-color-primary-tint: #ffd61a;

	--ion-color-secondary: #0089a6;
	--ion-color-secondary-rgb: 242, 131, 34;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary-shade: #d5731e;
	--ion-color-secondary-tint: #f38f38;

	--ion-color-tertiary: #e31114;
	--ion-color-tertiary-rgb: 227, 17, 20;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #c80f12;
	--ion-color-tertiary-tint: #e6292c;

	--ion-color-info: #0089a6;

	--ion-color-success: #6da608;
	--ion-color-success-rgb: 109, 166, 8;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #609207;
	--ion-color-success-tint: #7caf21;

	--ion-color-warning: #ff9b23;
	--ion-color-warning-rgb: 255, 155, 35;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0881f;
	--ion-color-warning-tint: #ffa539;

	--ion-color-danger: #f23e16;
	--ion-color-danger-rgb: 242, 62, 22;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d53713;
	--ion-color-danger-tint: #f3512d;

	--ion-color-dark: #0d0d0d;
	--ion-color-dark-rgb: 13, 13, 13;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #0b0b0b;
	--ion-color-dark-tint: #252525;

	--ion-color-medium: #848484;
	--ion-color-medium-rgb: 132, 132, 132;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #747474;
	--ion-color-medium-tint: #909090;

	--ion-color-light: #f2f2f2;
	--ion-color-light-rgb: 242, 242, 242;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d5d5d5;
	--ion-color-light-tint: #f3f3f3;

	--ion-color-warning2: #ed8a0a;
	--ion-color-warning2-rgb: 249, 168, 12;
	--ion-color-warning2-contrast: #000000;
	--ion-color-warning2-contrast-rgb: 0, 0, 0;
	--ion-color-warning2-shade: #f9a80c;
	--ion-color-warning2-tint: #fab124;

	--ion-color-safety: #9852f9;
	--ion-color-safety-rgb: 103, 58, 183;
	--ion-color-safety-contrast: #4638cf;
	--ion-color-safety-contrast-rgb: 70, 56, 207;
	--ion-color-safety-shade: #311b92;
	--ion-color-safety-tint: #9838cf;

	--ion-font-family: 'din', sans-serif;

	--ion-background-color: #101313;
	--ion-background-color-rgb: 18, 18, 18;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-border-color: #202424;

	--ion-color-step-50: #202424;
	--ion-color-step-100: #2a2c2c;
	--ion-color-step-150: #363636;
	--ion-color-step-200: #414141;
	--ion-color-step-250: #4d4d4d;
	--ion-color-step-300: #595959;
	--ion-color-step-350: #656565;
	--ion-color-step-400: #717171;
	--ion-color-step-450: #7d7d7d;
	--ion-color-step-500: #898989;
	--ion-color-step-550: #949494;
	--ion-color-step-600: #a0a0a0;
	--ion-color-step-650: #acacac;
	--ion-color-step-700: #b8b8b8;
	--ion-color-step-750: #c4c4c4;
	--ion-color-step-800: #d0d0d0;
	--ion-color-step-850: #dbdbdb;
	--ion-color-step-900: #e7e7e7;
	--ion-color-step-950: #f3f3f3;

	--ion-item-background: #202424;

	--ion-toolbar-background: #202424;

	--ion-tab-bar-background: #202424;

	--ion-card-background: #202424;
}

// @media (prefers-color-scheme: dark) {
// 	/*
//    * Dark Colors
//    * -------------------------------------------
//    */

// 	body {
// 		--ion-color-primary: #428cff;
// 		--ion-color-primary-rgb: 66, 140, 255;
// 		--ion-color-primary-contrast: #ffffff;
// 		--ion-color-primary-contrast-rgb: 255, 255, 255;
// 		--ion-color-primary-shade: #3a7be0;
// 		--ion-color-primary-tint: #5598ff;

// 		--ion-color-secondary: #50c8ff;
// 		--ion-color-secondary-rgb: 80, 200, 255;
// 		--ion-color-secondary-contrast: #ffffff;
// 		--ion-color-secondary-contrast-rgb: 255, 255, 255;
// 		--ion-color-secondary-shade: #46b0e0;
// 		--ion-color-secondary-tint: #62ceff;

// 		--ion-color-tertiary: #6a64ff;
// 		--ion-color-tertiary-rgb: 106, 100, 255;
// 		--ion-color-tertiary-contrast: #ffffff;
// 		--ion-color-tertiary-contrast-rgb: 255, 255, 255;
// 		--ion-color-tertiary-shade: #5d58e0;
// 		--ion-color-tertiary-tint: #7974ff;

// 		--ion-color-success: #2fdf75;
// 		--ion-color-success-rgb: 47, 223, 117;
// 		--ion-color-success-contrast: #000000;
// 		--ion-color-success-contrast-rgb: 0, 0, 0;
// 		--ion-color-success-shade: #29c467;
// 		--ion-color-success-tint: #44e283;

// 		--ion-color-warning: #ffd534;
// 		--ion-color-warning-rgb: 255, 213, 52;
// 		--ion-color-warning-contrast: #000000;
// 		--ion-color-warning-contrast-rgb: 0, 0, 0;
// 		--ion-color-warning-shade: #e0bb2e;
// 		--ion-color-warning-tint: #ffd948;

// 		--ion-color-danger: #ff4961;
// 		--ion-color-danger-rgb: 255, 73, 97;
// 		--ion-color-danger-contrast: #ffffff;
// 		--ion-color-danger-contrast-rgb: 255, 255, 255;
// 		--ion-color-danger-shade: #e04055;
// 		--ion-color-danger-tint: #ff5b71;

// 		--ion-color-dark: #f4f5f8;
// 		--ion-color-dark-rgb: 244, 245, 248;
// 		--ion-color-dark-contrast: #000000;
// 		--ion-color-dark-contrast-rgb: 0, 0, 0;
// 		--ion-color-dark-shade: #d7d8da;
// 		--ion-color-dark-tint: #f5f6f9;

// 		--ion-color-medium: #989aa2;
// 		--ion-color-medium-rgb: 152, 154, 162;
// 		--ion-color-medium-contrast: #000000;
// 		--ion-color-medium-contrast-rgb: 0, 0, 0;
// 		--ion-color-medium-shade: #86888f;
// 		--ion-color-medium-tint: #a2a4ab;

// 		--ion-color-light: #222428;
// 		--ion-color-light-rgb: 34, 36, 40;
// 		--ion-color-light-contrast: #ffffff;
// 		--ion-color-light-contrast-rgb: 255, 255, 255;
// 		--ion-color-light-shade: #1e2023;
// 		--ion-color-light-tint: #383a3e;
// 	}

// 	/*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

// 	.ios body {
// 		--ion-background-color: #000000;
// 		--ion-background-color-rgb: 0, 0, 0;

// 		--ion-text-color: #ffffff;
// 		--ion-text-color-rgb: 255, 255, 255;

// 		--ion-color-step-50: #0d0d0d;
// 		--ion-color-step-100: #1a1a1a;
// 		--ion-color-step-150: #262626;
// 		--ion-color-step-200: #333333;
// 		--ion-color-step-250: #404040;
// 		--ion-color-step-300: #4d4d4d;
// 		--ion-color-step-350: #595959;
// 		--ion-color-step-400: #666666;
// 		--ion-color-step-450: #737373;
// 		--ion-color-step-500: #808080;
// 		--ion-color-step-550: #8c8c8c;
// 		--ion-color-step-600: #999999;
// 		--ion-color-step-650: #a6a6a6;
// 		--ion-color-step-700: #b3b3b3;
// 		--ion-color-step-750: #bfbfbf;
// 		--ion-color-step-800: #cccccc;
// 		--ion-color-step-850: #d9d9d9;
// 		--ion-color-step-900: #e6e6e6;
// 		--ion-color-step-950: #f2f2f2;

// 		--ion-item-background: #000000;

// 		--ion-card-background: #1c1c1d;
// 	}

// 	.ios ion-modal {
// 		--ion-background-color: var(--ion-color-step-100);
// 		--ion-toolbar-background: var(--ion-color-step-150);
// 		--ion-toolbar-border-color: var(--ion-color-step-250);
// 	}

// 	/*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

// 	.md body {
// 		--ion-background-color: #121212;
// 		--ion-background-color-rgb: 18, 18, 18;

// 		--ion-text-color: #ffffff;
// 		--ion-text-color-rgb: 255, 255, 255;

// 		--ion-border-color: #222222;

// 		--ion-color-step-50: #1e1e1e;
// 		--ion-color-step-100: #2a2a2a;
// 		--ion-color-step-150: #363636;
// 		--ion-color-step-200: #414141;
// 		--ion-color-step-250: #4d4d4d;
// 		--ion-color-step-300: #595959;
// 		--ion-color-step-350: #656565;
// 		--ion-color-step-400: #717171;
// 		--ion-color-step-450: #7d7d7d;
// 		--ion-color-step-500: #898989;
// 		--ion-color-step-550: #949494;
// 		--ion-color-step-600: #a0a0a0;
// 		--ion-color-step-650: #acacac;
// 		--ion-color-step-700: #b8b8b8;
// 		--ion-color-step-750: #c4c4c4;
// 		--ion-color-step-800: #d0d0d0;
// 		--ion-color-step-850: #dbdbdb;
// 		--ion-color-step-900: #e7e7e7;
// 		--ion-color-step-950: #f3f3f3;

// 		--ion-item-background: #1e1e1e;

// 		--ion-toolbar-background: #1f1f1f;

// 		--ion-tab-bar-background: #1f1f1f;

// 		--ion-card-background: #1e1e1e;
// 	}
// }

.rdsg-timeline-modal {
	&.fullscreen .modal-wrapper {
		width: 100vw;
		height: 100vh;
	}

	.modal-wrapper {
		min-width: 90%;
		min-height: 80vh;
	}
}

.rdsg-cam-view-modal {
	&.fullscreen .modal-wrapper {
		width: 100vw;
		height: 100vh;
	}

	.modal-wrapper {
		min-width: 50vw;
		height: calc(50vw * 0.5625 + 56px);
	}
}

@media screen and (min-height: 800px) {
	.rdsg-timeline-modal .modal-wrapper {
		min-height: 60vh;
	}

	.rdsg-cam-view-modal .modal-wrapper {
		min-height: 60vh;
	}
}

@media screen and (max-width: 960px) {
	.rdsg-cam-view-modal .modal-wrapper {
		min-width: 50%;
		height: var(--height);
	}
}

@media screen and (min-width: 1420px) {
	.rdsg-timeline-modal .modal-wrapper {
		min-width: 70%;
	}

	.rdsg-cam-view-modal .modal-wrapper {
		min-width: 50%;
	}
}
